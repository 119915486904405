<template>
  <div class="container">
    <div class="section">
      <h1 class="title">Estamos activando tu cuenta de usuario...</h1>
        <v-card flat min-height="400px" class="text-center">
            <h1 class="mt-4">Cargando pedidos ...</h1>
        <v-img src="/media/loader/loading.gif" width="300px" height="300px" style="margin:0 auto;"/>
    </v-card>
    </div>
        <v-snackbar
            top
            :timeout=4000
            v-model="snackbar.show"
            color="#4CAF50"
        >
            {{ snackbar.text }}
            <v-btn
            color="black"
            text
            @click="snackbar.show = false"
            >
            Close
            </v-btn>
        </v-snackbar>    
  </div>
</template>

<script>
export default {
  data(){
      return{
        snackbar:{
            show:false,
            text:''
        },
      }
  },
  created() {
    this.activateUser()
  },
  methods: {
    activateUser() {
      const { hash } = this.$route.params
      this.$store.dispatch('auth/activateUser', hash)        
        .then(() =>this.showSnackbar("Usuario registrado con éxito" ))
        .catch(err => console.log(err))
    },
    showSnackbar(msg){
        this.snackbar.text=msg
        this.snackbar.show=true
    },
  }
}
</script>

<style scoped>
  .title {
    font-size: 50px;
    text-align: center;
    font-family: 'Montserrat', sans-serif !important;
  }
</style>